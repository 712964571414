<template>
  <li>
    <div class="top-row">
      <flag
        :iso="element.flag"
        :squared="false"
        class="country-flag"
        v-tooltip="element.country"
      />

      <div class="fluids-container">
        <i
          v-for="(fluid, index) in knownFluids"
          :key="index"
          :class="getFluidIconClass(fluid)"
          :style="{ color: getFluidIconColor(fluid) }"
          v-tooltip="getFluidDisplayName(fluid)"
        />
      </div>
    </div>

    <div class="center-section">
      <img
        loading="lazy"
        v-if="element.logo"
        :src="'/img/' + element.logo"
        alt="logo"
      >
      <span v-else>{{ element.name }}</span>

      <p v-if="element.logo">
        {{ element.name }}
      </p>
    </div>

    <i
      v-if="element.captcha"
      class="fas fa-lock captcha-icon"
      v-tooltip="'Automated reading of consumption data. Supplier is blocking automated download of files.'"
    />
  </li>
</template>

<script>
import { MAPFLUID } from '@/mapfluid.js';

const fluidIconMap = MAPFLUID.reduce((map, fluid) => {
  map[fluid.id] = fluid;
  return map;
}, {});

export default {
  props: {
    element: { default: null, type: Object },
  },
  computed: {
    knownFluids() {
      if (!this.element || !Array.isArray(this.element.fluids)) {
        return [];
      }
      const seen = new Set();
      return this.element.fluids.filter(fluidId => {
        const fluidData = fluidIconMap[fluidId];
        const isKnown = fluidData && fluidData.icon !== 'fas fa-question';
        if (isKnown && !seen.has(fluidId)) {
          seen.add(fluidId);
          return true;
        }
        return false;
      });
    }
  },
  methods: {
    getFluidIconClass(fluidId) {
      return fluidIconMap[fluidId]?.icon || 'fas fa-question';
    },
    getFluidIconColor(fluidId) {
      return fluidIconMap[fluidId]?.color || '#777';
    },
    getFluidDisplayName(fluidId) {
      return fluidIconMap[fluidId]?.display_name || 'Unknown';
    }
  }
};
</script>


<style lang="scss" scoped>
li {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 220px;
  margin: 0 12px 12px 0;
  padding: 8px;
  background-color: white;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),
              0 2px 2px 0 rgba(0,0,0,.14),
              0 1px 5px 0 rgba(0,0,0,.12);
  border-radius: 3px;
  list-style: none;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.01);
  }

  img {
    width: 100px;
    max-height: 80px;
    object-fit: contain;
    margin: auto;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 60px;
    font-size: 20px;
    font-weight: 300;
    font-family: sans-serif;
  }

  p {
    margin: 4px 0 0 0;
    padding: 4px;
    text-align: center;
    color: rgb(255, 152, 0);
    font-family: "Lato", sans-serif;
    font-weight: 100;
    font-variant-ligatures: normal;
    font-size: 1rem;
    letter-spacing: 1px;
  }
}

.top-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.country-flag {
  width: 26px;
}

.fluids-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 140px;
  gap: 8px;
}

.fluids-container i {
  font-size: 16px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
}

.fluids-container i:hover {
  color: #333;
}

.center-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.captcha-icon {
  position: absolute;
  bottom: 8px;
  right: 8px;
  color: rgb(255, 152, 0);
  font-size: 18px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.captcha-icon:hover {
  transform: scale(1.1);
  color: #f90;
}
</style>
