export const MAPFLUID = [
  {
    id: 'electricity',
    display_name: 'Electricity',
    color: '#2196f3',
    icon: 'fa-fw fas fa-bolt',
  },
  {
    id: 'water',
    display_name: 'Water',
    color: '#64b5f6',
    icon: 'fa-fw fas fa-tint',
  },
  {
    id: 'gas',
    display_name: 'Gas',
    color: '#ffb74d',
    icon: 'fa-fw fas fa-fire',
  },
  {
    id: 'district_heating',
    display_name: 'District heating',
    color: '#ff8a65',
    icon: 'fa-fw fas fa-network-wired',
  },
  {
    id: 'district_cooling',
    display_name: 'District cooling',
    color: '#80d8ff',
    icon: 'fa-fw fas fa-network-wired',
  },
  {
    id: 'fuel',
    display_name: 'Fuel',
    color: '#455a64',
    icon: 'fa-fw fas fa-gas-pump',
  },
  {
    id: 'waste',
    display_name: 'Waste',
    color: '#424242',
    icon: 'fa-fw fas fa-trash-alt',
  },
  {
    id: 'propane',
    display_name: 'Propane',
    color: '#bf360c',
    icon: 'fa-fw fas fa-burn',
  },
  {
    id: 'photovoltaic',
    display_name: 'Photovoltaic',
    color: '#fbc02d',
    icon: 'fa-fw fas fa-sun',
  },
  {
    id: 'pellets',
    display_name: 'Pellets',
    color: '#795548',
    icon: 'fas fa-cubes',
  },
  {
    id: 'refrigerant',
    display_name: 'Refrigerant',
    color: '#4299cf',
    icon: 'fa-fw fas fa-snowflake',
  },
  {
    id: 'wood_chips',
    display_name: 'Wood chips',
    color: '#317012',
    icon: 'fas fa-tree',
  },
];
