import Vue from 'vue'
import VTooltip from 'v-tooltip'
import Router from 'vue-router'
import ConnectorPage from './views/ConnectorPage.vue'
import './styles.css'

Vue.use(Router)

Vue.use(VTooltip, {
  defaultHtml: false,
  defaultDelay: { show: 100, hide: 200 },
  defaultPlacement: 'top',
  defaultClass: 'beautified-tooltip',
  defaultArrowSelector: '.tooltip-arrow',
  defaultInnerSelector: '.tooltip-inner',
  popover: {
    defaultTrigger: 'hover focus',
    defaultDelay: { show: 200, hide: 200 },
  },
})

export default new Router({
  routes: [
    {
      path: '/',
      name: 'connector-page',
      component: ConnectorPage
    },
  ]
})
