<template>
  <main>
    <div
      v-if="connectors"
      class="container"
    >
      <div class="filter">
        <filter-list
          :json="areaOptions"
          title="Area"
          @model="onModelChangeArea"
        />
        <filter-list
          ref="countryRef"
          :json="countryOptions"
          title="Country"
          @model="onModelChangeCountry"
        />
      </div>
      <div class="filter">
        <filter-list
          ref="utilityRef"
          :json="utilityOptions"
          title="Utility"
          @model="onModelChangeUtility"
        />
        <filter-list
          ref="familyRef"
          :json="familyOptions"
          title="Category"
          @model="onModelChangeFamily"
        />
      </div>

      <div class="row infoRow">
        <div class="infoBox">
          <span class="big">{{ selectedConnectorsCount }}</span>&nbsp;
          <span>connectors</span>
        </div>
        <div class="row">
          <p class="title_infos">
            Here are all the connections we have with utility suppliers and data providers around the world. If the data provider you are looking for is not in this list, you can contact our Sales or Client team: we develop new connectors every week!
          </p>
        </div>
      </div>

      <connectors-list
        :raw-connectors="connectors"
        :family="selectedFamily"
        :area="selectedArea"
        :country="selectedCountry"
        :fluids="selectedUtility"
        :limit="displayedConnectorsLimit"
        @connectors-range="selectedConnectorsCount = $event"
      />

      <div
        class="pagination"
        v-if="selectedConnectorsCount > displayedConnectorsLimit"
      >
        <button @click="loadMore">
          Load more
        </button>
      </div>
    </div>

    <div v-else>
      No Data
    </div>
  </main>
</template>

<script>
import connectorsData from '@/connectors.json'
import connectorsMetaData from '@/all_metadata.json'
import {isNonEmpty, needToResetField} from "@/utils"
import ConnectorsList from '@/components/ConnectorsList'
import FilterList from '@/components/FilterList'

const DEFAULT_CONNECTORS_LIMIT = 1000

export default {
  name: 'ConnectorPage',
  components: {
    ConnectorsList,
    FilterList
  },
  data() {
    const allConnectors = connectorsData.connectors
    const allMetaData = connectorsMetaData.connectors
    const allAreas = Object.keys(allMetaData)
    const allCountries = Object.keys(allMetaData["all"])
    const allUtilities = Object.keys(allMetaData["all"]["all"])
    const allFamilies = allMetaData["all"]["all"]["all"]
    return {
      connectors: allConnectors,
      selectedConnectorsCount: allConnectors.length,
      connectorsMetaData: allMetaData,
      displayedConnectorsLimit: DEFAULT_CONNECTORS_LIMIT,

      listArea: allAreas,
      availableArea: allAreas,
      selectedArea: [],

      listCountry: allCountries,
      availableCountry: allCountries,
      selectedCountry: [],

      listUtility: allUtilities,
      availableUtility: allUtilities,
      selectedUtility: [],

      listFamily: allFamilies,
      availableFamily: allFamilies,
      selectedFamily: [],
    }
  },
  methods: {
    onModelChangeArea(newValue) {
      this.resetDisplayedConnectorsLimit()
      this.selectedArea = newValue || []
      const newArea = isNonEmpty(newValue) ? newValue[0] : "all"
      const updatedCountry = this.updateCountry(newArea)
      const updatedUtility = this.updateUtility(newArea, updatedCountry)
      this.updateFamily(newArea, updatedCountry, updatedUtility)
    },
    onModelChangeCountry(newValue) {
      this.resetDisplayedConnectorsLimit()
      this.selectedCountry = newValue || []
      const newCountry = isNonEmpty(newValue) ? newValue[0] : "all"
      const currentArea = isNonEmpty(this.selectedArea) ? this.selectedArea[0] : "all"
      const updatedUtility = this.updateUtility(currentArea, newCountry)
      this.updateFamily(currentArea, newCountry, updatedUtility)
    },
    onModelChangeUtility(newValue) {
      this.resetDisplayedConnectorsLimit()
      this.selectedUtility = isNonEmpty(newValue) ? [unhumanizeUtilityName(newValue[0])] : []
      const newUtility = isNonEmpty(newValue) ? unhumanizeUtilityName(newValue[0]) : "all"
      const currentArea = isNonEmpty(this.selectedArea) ? this.selectedArea[0] : "all"
      const currentCountry = isNonEmpty(this.selectedCountry) ? this.selectedCountry[0] : "all"
      this.updateFamily(currentArea, currentCountry, newUtility)
    },
    onModelChangeFamily(newValue) {
      this.resetDisplayedConnectorsLimit()
      this.selectedFamily = newValue
    },
    loadMore() {
      this.displayedConnectorsLimit += this.displayedConnectorsLimit
    },
    updateCountry(newArea) {
      let currentCountry = isNonEmpty(this.selectedCountry) ? this.selectedCountry[0] : null
      this.availableCountry = Object.keys(this.connectorsMetaData[newArea])
      if (needToResetField(this.availableCountry, currentCountry)) {
        this.resetSelectionField("selectedCountry", "countryRef")
      }
      return isNonEmpty(this.selectedCountry) ? currentCountry : "all"
    },
    updateUtility(newArea, newCountry) {
      let currentUtility = isNonEmpty(this.selectedUtility) ? unhumanizeUtilityName(this.selectedUtility[0]) : null
      this.availableUtility = Object.keys(this.connectorsMetaData[newArea][newCountry])
      if (needToResetField(this.availableUtility, currentUtility)) {
        this.resetSelectionField("selectedUtility", "utilityRef")
      }
      return isNonEmpty(this.selectedUtility) ? currentUtility : "all"
    },
    updateFamily(newArea, newCountry, newUtility) {
      let currentFamily = isNonEmpty(this.selectedFamily) ? this.selectedFamily[0] : null
      this.availableFamily = this.connectorsMetaData[newArea][newCountry][newUtility]
      if (needToResetField(this.availableFamily, currentFamily)) {
        this.resetSelectionField("selectedFamily", "familyRef")
      }
    },
    resetSelectionField(selectionRefName, ref) {
      this[selectionRefName] = []
      Object.assign(this.$refs[ref].$data, { selected: [] })
    },
    resetDisplayedConnectorsLimit() {
      this.displayedConnectorsLimit = DEFAULT_CONNECTORS_LIMIT
    },
    filterAllValue(elements) {
      return elements.filter(element => element.toLowerCase() !== "all")
    }
  },
  computed: {
    areaOptions() {
      return this.filterAllValue([...this.listArea]).sort()
    },
    countryOptions() {
      return this.filterAllValue([...this.availableCountry]).sort()
    },
    familyOptions() {
      return this.filterAllValue([...this.availableFamily]).sort()
    },
    utilityOptions() {
      return this.filterAllValue([...this.availableUtility])
          .map(u => humanizeUtilityName(u))
          .sort()
    }
  }
}

function humanizeUtilityName(utility) {
  const spaced = utility.replace(/_/g, ' ')
  return spaced.charAt(0).toUpperCase() + spaced.slice(1)
}

function unhumanizeUtilityName(utility) {
  if (isNonEmpty(utility)) {
    return utility.toLowerCase().replace(/\s+/g, '_')
  }
  return null
}

</script>

<style lang="scss">
body {
  margin: 0;
  background-color: #f9f9f9;
  font-family: "Lato", sans-serif;
}

.container {
  width: 100%;
  max-width: 1185px;
  margin: auto;
  padding: 24px;
  box-sizing: border-box;
  @media (max-width: 425px) {
    padding: 12px;
  }
}

.filter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2%;
  margin-bottom: 24px;
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  font-weight: 100;
  font-size: 1rem;
  letter-spacing: 1px;
}

.infoRow {
  max-width: 91%;
}

.title_infos {
  display: flex;
  flex-direction: row;
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: 1px;
  padding-left: 25px;
}

.infoBox {
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  max-width: 200px;
  height: 64px;
  padding: 0 8px;
  margin-right: 16px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2),
              0 2px 2px 0 rgba(0, 0, 0, .14),
              0 1px 5px 0 rgba(0, 0, 0, .12);
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
  span {
    font-size: 14px;
  }
  .big {
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    color: #555;
  }
}

.vs__dropdown-option {
  min-height: 28px;
  &--selected {
    background-color: #5897fb;
    color: white !important;
  }
}

.vs__actions {
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.pagination button {
  padding: 8px 16px;
  border: none;
  background-color: #5897fb;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .filter {
    flex-direction: column;
  }
  .infoBox {
    margin-bottom: 16px;
  }
}
</style>
