<template>
  <transition-group
    name="fade"
    tag="ul"
  >
    <connector
      v-for="(item, index) in connectors.slice(0, limit)"
      :key="`${item.name}-${index}`"
      :element="item"
    />
  </transition-group>
</template>

<script>
import Connector from './Connector'
import _ from 'underscore'

export default {
    components: {
        Connector
    },
    props: {
        rawConnectors: { default: null, type: Array },
        family: { default: () => [], type: Array },
        area: { default: () => [], type: Array },
        country: { default: () => [], type: Array },
        fluids: { default: () => [], type: Array },
        limit: { default: 1000, type: Number },
    },
    watch: {
      connectors() {
        this.$emit('connectors-range', this.connectors.length)
      }
    },
    computed: {
      connectors() {
        let connectorsToDisplay = [...this.rawConnectors]


        connectorsToDisplay = connectorsToDisplay.filter(connector =>
          this.connectorInArea(connector) &&
          this.connectorInCountry(connector) &&
          this.connectorWithUtility(connector) &&
          this.connectorWithFamily(connector)
         )
        return _.sortBy(connectorsToDisplay, 'name')
      },
    },
  methods: {
      connectorInArea(connector) {
        if (!(this.area && this.area.length)) {
          return true
        }
        return connector.area === this.area[0]
      },
      connectorInCountry(connector) {
        if (!(this.country && this.country.length)) {
          return true
        }
        return connector.country === this.country[0]
      },
      connectorWithUtility(connector) {
        if (!(this.fluids && this.fluids.length)) {
          return true
        }
        return Array.isArray(connector.fluids) && connector.fluids.some(fl => this.fluids.includes(fl))
      },
      connectorWithFamily(connector) {
        if (!(this.family && this.family.length)) {
          return true
        }
        return connector.family.includes(this.family[0])
      }
  }
}
</script>

<style lang="scss" scoped>
ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin: 0;
    padding: 0;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateX(-1000px);
}

</style>
